* {
  font-family: Helvetica, Arial, sans-serif;
}
body {
  padding: 60px;
}

button{
  padding: auto;
}

input{
  color: gray;
  font-size: 1.2rem;
  width: 100%;
}

.card{
  align-content: center;
  border: 1px solid grey;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-right: 10px;
  overflow: hidden;
}

.card p {
  padding: 5px;
  text-align: center;
}

.card img {
  max-height: 170px;
}

.card-grid{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}